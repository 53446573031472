@import "assets/styles/mat-theme";

/* Components */
@import "assets/styles/fonts";
@import "assets/styles/table";
@import "assets/styles/tabs";
@import "assets/styles/form-fields";
@import "assets/styles/mat-spinner";
@import "assets/styles/mat-snakbar";
@import "assets/styles/wallet-style";
@import "assets/styles/mat-dialog";

/* You can add global styles to this file, and also import other style files */

/* Angular Material */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family:
    HarmonyOS Sans,
    sans-serif;
}

.json-green {
  color: #008080CC;
}

:root {
  --mat-sidenav-content-background-color: #fff;
}

.light {
  :root {
    --mat-sidenav-content-text-color: #45525b;
  }

  body {
    color: #022d35;
  }
}

.dark {
  :root {
    --mat-sidenav-content-text-color: white;
  }

  body {
    color: white;
  }
}

/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* Telegram ThemeParams */
//body {
//  background-color: var(--tg-theme-bg-color);
//  color: var(--tg-theme-text-color);
//}
