.mat-mdc-form-field {
  --mat-form-field-container-text-size: 20px;
  --mdc-outlined-text-field-container-shape: 8px;

  .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
    z-index: 10;
  }
}

.light {
  .mat-mdc-form-field {
    --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.20);
    --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.20);
    --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.35);

    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
      background: #F7F7F7;
    }
  }
}


.textarea-fix-padding {
  .mat-mdc-form-field-infix {
    padding-bottom: 8px !important;
  }
}
