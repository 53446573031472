.fraud-table {
  width: 100%;

  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  color: #022D35;

  font-size: 12px;

  tr {
    td {
      padding: 5px 0;
      border-bottom: 1px solid #0000001A;

      &:nth-child(1) {
        padding-right: 8px;
      }
      &:nth-child(2) {
        text-align: right;
      }
    }
    &:first-child {
      td {
        border-top: 1px solid #0000001A;
      }
    }
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  &.big-cell-padding {
    tr {
      td {
        padding: 10px 0;
      }
    }
  }

  &.fraud-table-white {
    color: white;
    tr {
      td {
        border-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
}
