@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('/assets/fonts/HarmonyOS_Sans_Thin.eot');
  src: local('HarmonyOS Sans Thin'), local('HarmonyOS_Sans_Thin'),
  url('/assets/fonts/HarmonyOS_Sans_Thin.woff2') format('woff2'),
  url('/assets/fonts/HarmonyOS_Sans_Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('/assets/fonts/HarmonyOS_Sans_Light.eot');
  src: local('HarmonyOS Sans Light'), local('HarmonyOS_Sans_Light'),
  url('/assets/fonts/HarmonyOS_Sans_Light.woff2') format('woff2'),
  url('/assets/fonts/HarmonyOS_Sans_Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('/assets/fonts/HarmonyOS_Sans.eot');
  src: local('HarmonyOS Sans'), local('HarmonyOS_Sans'),
  url('/assets/fonts/HarmonyOS_Sans.woff2') format('woff2'),
  url('/assets/fonts/HarmonyOS_Sans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('/assets/fonts/HarmonyOS_Sans_Medium.eot');
  src: local('HarmonyOS Sans Medium'), local('HarmonyOS_Sans_Medium'),
  url('/assets/fonts/HarmonyOS_Sans_Medium.woff2') format('woff2'),
  url('/assets/fonts/HarmonyOS_Sans_Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('/assets/fonts/HarmonyOS_Sans_Bold.eot');
  src: local('HarmonyOS Sans Bold'), local('HarmonyOS_Sans_Bold'),
  url('/assets/fonts/HarmonyOS_Sans_Bold.woff2') format('woff2'),
  url('/assets/fonts/HarmonyOS_Sans_Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('/assets/fonts/HarmonyOS_Sans_Black.eot');
  src: local('HarmonyOS Sans Black'), local('HarmonyOS_Sans_Black'),
  url('/assets/fonts/HarmonyOS_Sans_Black.woff2') format('woff2'),
  url('/assets/fonts/HarmonyOS_Sans_Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
